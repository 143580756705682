<template>
  <el-main>
    <div class="flex" style="margin-bottom: 50px;">
      <div style="margin-right: 20px;">
        <p class="demonstration">{{ __("Breakdown") }}</p>
        <el-select
          v-model="filters.breakdown"
          placeholder="Select"
          default-first-option
        >
          <el-option :label="__('Hourly')" value="hourly"></el-option>
          <el-option :label="__('Daily')" value="daily"></el-option>
          <el-option :label="__('Monthly')" value="monthly"></el-option>
        </el-select>
      </div>
      <div class="pr-6">
        <p class="demonstration">Date</p>
        <el-date-picker
          v-model="filters.selectedDate"
          v-show="isFiltersBreakdownHourly"
          type="date"
          :placeholder="__('Pick a day')"
          :format="displayDateFormat"
          value-format="yyyy-MM-dd"
          align="right"
        ></el-date-picker>
        <el-date-picker
          v-model="filters.selectedDates"
          v-show="!isFiltersBreakdownHourly"
          type="daterange"
          align="right"
          unlink-panels
          :range-separator="__('To')"
          :start-placeholder="__('Start date')"
          :end-placeholder="__('End date')"
          :picker-options="pickerOptions"
          :format="displayDateFormat"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>

    <div>
      <peak-sessions
        :from-date="fromDate"
        :to-date="toDate"
        :breakdown="filters.breakdown"
        :service-provider-id="selectedServiceProviderId"
        :account-id="selectedAccountId"
        :key="componentKey"
      ></peak-sessions>
    </div>
  </el-main>
</template>

<script>
import PeakSessions from "@/views/analyse/components/peakSessions";
import { mapGetters, mapState } from "vuex";
import moment from "moment-timezone";

export default {
  name: "MaximumVirtualAgentTracker",
  components: {
    PeakSessions
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat"
    }),

    fromDate() {
      if (this.filters.breakdown === "hourly") {
        return this.filters.selectedDate
          ? this.filters.selectedDate
          : moment().format("YYYY-MM-DD");
      } else if (
        this.filters.selectedDates &&
        this.filters.selectedDates.length > 1
      ) {
        return this.filters.selectedDates[0];
      } else {
        return moment().format("YYYY-MM-DD");
      }
    },

    toDate() {
      if (this.filters.breakdown === "hourly") {
        return this.filters.selectedDate
          ? this.filters.selectedDate
          : moment().format("YYYY-MM-DD");
      } else if (
        this.filters.selectedDates &&
        this.filters.selectedDates.length > 1
      ) {
        return this.filters.selectedDates[1];
      } else {
        return moment().format("YYYY-MM-DD");
      }
    },
    /**
     * check filter breakdown is hourly
     * @returns {boolean}
     */
    isFiltersBreakdownHourly() {
      return this.filters.breakdown === "hourly";
    }
  },
  data() {
    return {
      componentKey: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: __("Last week"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last month"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last 3 months"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      filters: {
        selectedDate: "",
        selectedDates: "",
        breakdown: "hourly"
      }
    };
  },

  watch: {
    filters: {
      handler() {
        console.log("changed");
        this.componentKey++;
      },
      deep: true
    }

    // selectedDates() {
    //   this.componentKey++
    // },
    // selectedDate() {
    //   this.componentKey++
    // },
    // breakdown() {
    //   this.componentKey++
    // }
  }
};
</script>

<style scoped></style>
