<template>
  <div v-loading="isLoading">
    <highcharts
      class="chart"
      :options="getChartOptions"
      :updateArgs="updateArgs"
    ></highcharts>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "peak-sessions",
  props: {
    fromDate: {
      required: true,
      type: String
    },
    toDate: {
      required: true,
      type: String
    },
    breakdown: {
      default: "hourly",
      type: String
    },
    serviceProviderId: {
      required: true
    },
    accountId: {
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          type: "column"
        },
        credits: {
          enabled: false
        },
        title: {
          text: __("peak sessions")
        },
        xAxis: {
          type: "category",
          title: {
            text: ""
          }
        },
        yAxis: {
          title: {
            text: __("Max Sessions")
          }
        },
        series: [
          {
            name: "Sessions",
            data: [],
            color: "#6fcd98"
          }
        ]
      }
    };
  },
  watch: {
    selectedIds: {
      immediate: true,
      handler() {
        this.initChart();
      }
    }
  },
  methods: {
    ...mapActions("peaksessions", {
      getAcPeakSessions: "getAcPeakSessions",
      getSpPeakSessions: "getSpPeakSessions",
      getPopPeakSessions: "getPopPeakSessions"
    }),

    async initChart() {
      this.isLoading = true;
      let process = this.getAcPeakSessions;

      if (this.selectedAccountId !== "all") {
        process = this.getAcPeakSessions;
      } else if (this.selectedServiceProviderId !== "all") {
        process = this.getSpPeakSessions;
      } else {
        process = this.getPopPeakSessions;
      }

      try {
        const data = await process({
          from_date: this.fromDate,
          to_date: this.toDate,
          breakdown: this.breakdown
        });

        this.chartOptions.series[0].data = data.map(a => {
          return {
            y: a.peak_active_call,
            name:
              this.breakdown === "hourly"
                ? moment(String(a.x_label).concat(":00"), "H:mm").format(
                    "HH:mm"
                  )
                : a.x_label
          };
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  },
  computed: {
    /**
     * capitalize the breakdown first letter
     * @returns {string}
     */
    capitalizeBreakdownFirstLetter() {
      return this.breakdown.charAt(0).toUpperCase() + this.breakdown.slice(1);
    },
    /**
     * get the chart options via computed prop so will update correctly when breakdown toggled
     * @returns {{xAxis: {type: string}, credits: {enabled: boolean}, series: [{data: [], color: string, name: string}], title: {text: string}, chart: {type: string}}}
     */
    getChartOptions() {
      let getChartOptions = _.cloneDeep(this.chartOptions);

      getChartOptions.series[0].name = this.capitalizeBreakdownFirstLetter;
      getChartOptions.title.text = __(":capitalize peak sessions", {
        capitalize: __(this.capitalizeBreakdownFirstLetter)
      });
      return getChartOptions;
    },
    selectedIds() {
      return [this.accountId, this.serviceProviderId];
    }
  }
};
</script>
